.invalid-feedback {
  font-size: 83.3335%;
}

.input-group-prepend {
  .option-btn-left.show-drop & {
    border-bottom-left-radius: 0;
  }

  .form-control:focus + & {
    border-color: $input-focus-border-color;
  }

  .form-control.is-invalid + &,
  .was-validated .form-control:invalid:focus + & {
    border-color: $red;
  }

  .form-control.is-valid + &,
  .form-control.is-valid:focus + & {
    border-color: $green;
  }

  .ico-flag {
    display: inline-block;
    vertical-align: -0.1875rem;
    font-size: 1.25rem;
    line-height: 1;
    margin-right: 4px;

    &:empty {
      width: 18px;
      height: 14px;
      vertical-align: -0.125rem;
      overflow: hidden;
      background-color: $flag-bg-color;
      margin-right: 9px;
    }
  }

  .country-code {
    font-weight: bold;
  }
}

.option-btn-left {
  > .form-control {
    border-left-width: 0;
    border-radius: 0 $border-radius $border-radius 0 !important;
    padding-left: 0.5rem;
  }

  &.show-drop > .form-control {
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend {
    margin-right: 0;
    border-style: solid;
    border-color: $input-border-color;
    border-width: 1px 0 1px 1px;
    transition: border-color $transition-speed-base ease-in-out;

    &,
    .dropdown-toggle {
      border-radius: $border-radius 0 0 $border-radius !important;
    }

    .is-valid + & {
      color: $white;
      border-color: $blue !important;
    }
  }

  .dropdown-toggle {
    border: 0;
    padding-right: 0.5rem;
  }
}

.input-group-dropdown {
  position: relative;

  .dropdown-toggle {
    color: rgba(255, 255, 255, 1);
    box-shadow: none !important;
    background: transparent !important;
    outline: none !important;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;

    &.flag-inactive {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus,
    &:active {
      color: $white;
    }
  }
}

.dropdown {
  &.show .btn.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
  }
}

.dropdown-menu {
  max-height: 21.375rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0.3125rem 0;
  margin-top: 0;
  margin-left: -5px;
  border-color: $secondary;

  .input-group-dropdown & {
    left: -1px !important;
    right: 1px !important;
    border-color: $input-border-color;
    border-radius: 0 0 $border-radius $border-radius;
    margin: 0;
    padding: 0.5rem;
  }
}

.dropdown-item {
  background-color: $dropdown-bg;
  border-radius: 0 !important;
  padding: 0.375rem 0.875rem;
  border-bottom: 0;

  &:focus,
  &:hover {
    color: $white;
    // outline: 1px dotted #fff;
    // outline-offset: -1px;
    background-color: $dropdown-link-active-bg;
    background: $gray-alt;
    outline: none;
  }

  .input-group-dropdown & {
    border: 0;
    padding-left: $form-check-inline-margin-x;
    padding-left: 0.25rem;
    padding-bottom: 0.375rem;

    > div {
      width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.dropdown-toggle {
  font-weight: 500;

  &::after {
    color: $white;
    border-left-width: 0.2857em;
    border-right-width: 0.2857em;
    border-top-width: 0.357em;
    vertical-align: 0.07145em;
    margin-left: 0.5715em;
  }

  .form-control:focus + .input-group-prepend & {
    background: $input-focus-bg !important;
  }
}

// .input-group-prepend button,
// .input-group-dropdown button > div {
//   &:first-letter {
//     font-size: 20px;
//     vertical-align: -4px;
//   }
// }
