.table {
  font-size: 0.875rem;
  line-height: 1.4286;
  margin-bottom: $table-offset-btm;
  border-radius: 0 0 $border-radius $border-radius;

  th,
  td {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    border: none;
  }

  &.rows-sm {
    th {
      font-weight: inherit;
    }

    th,
    td {
      padding: 0.5rem 0;
    }

    tbody tr:last-child {
      th,
      td {
        padding-bottom: 1rem;
      }
    }

    tfoot tr:first-child {
      th,
      td {
        padding-top: 1rem;
      }
    }
  }

  &.rows-xs {
    th,
    td {
      padding: 0.25rem 0;
    }
  }

  thead {
    th,
    td {
      padding-top: 0.8125rem;
      padding-bottom: 0.75rem;
      background-color: $table-head-bg;
      border-bottom: 0;
    }
  }

  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }

      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: 1rem;

    &:not(.table-fixed-rows) {
      thead {
        display: none;
      }

      tr,
      th,
      td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      tr {
        padding-bottom: 0.875rem;
      }

      tbody {
        tr,
        td {
          float: left;
          display: block;
          width: 100%;
        }

        td::before {
          display: block;
          content: attr(data-label);
          font-weight: 700;
          margin: 0 0.25rem 0.25rem 0;
          font-size: 0.6875rem;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    th,
    td {
      &:fisrt-child {
        padding-left: 1.5rem;
      }
    }
  }
}

.table-hover {
  tr {
    transition: background-color $transition-speed-base $transition-function-base;
  }
}

// .table-striped {
//   tbody tr {
//     &:hover {

//     }

//     &:nth-of-type(odd) {

//     }
//   }
// }

// .table-hover {
//   tbody tr {
//     &:hover {
//       background-color: rgba($table-bg, .5);
//     }

//     &:nth-of-type(odd):hover {
//       background-color: rgba($table-accent-bg, .5);
//     }
//   }
// }

.link-save,
.link-edit,
.link-delete {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 20px;
  overflow: hidden;
  text-indent: -9999px;
  margin-right: 1rem;
  opacity: 0.18;
  padding: 0;
  transition: opacity $transition-speed-base $transition-function-base;
  border: 0;

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 0 50%;
    cursor: pointer;
    text-indent: 0;
  }

  .disabled {
    cursor: default;
  }

  @include media-breakpoint-down(sm) {
    opacity: 0.4;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.link-save {
  opacity: 1;

  &:disabled {
    cursor: default;
  }

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    opacity: 0.8;
  }

  @include media-breakpoint-down(sm) {
    opacity: 0.4;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.link-edit::after {
  background-image: $edit-icon;
}

.link-save::after {
  background-image: $save-icon;
}

.link-delete::after {
  background-image: $delete-icon;
}
