.progress-holder {
  overflow: hidden;
}

.progress-bar {
  height: 0.375rem;
  border-radius: 0.25rem;
  background-color: $navy-light;
  margin-bottom: 0.3125rem;
  overflow: hidden;
  position: relative;

  table & {
    max-width: 7rem;
  }

  div {
    height: 100%;
    background-color: $white;
    border-radius: 6px;
  }

  .table-hover & {
    transition: background-color $transition-speed-base $transition-function-base;
  }

  .table-hover tr:hover & {
    background-color: $secondary;
  }
}

.progress-title {
  font-size: 0.75rem;
  line-height: 1.3335;
  font-weight: inherit;
  display: block;
}
