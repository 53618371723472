.invite-row {
  margin-bottom: $form-group-margin-bottom * 2;

  .col {
    margin-bottom: 1rem;
  }

  .col-btn {
    margin-top: 1.75rem;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: $form-group-margin-bottom;

    .col {
      margin-bottom: 0;
    }

    .col-btn {
      margin-top: 0;
    }

    label {
      display: none;
    }

    &:first-child {
      .col-btn {
        margin-top: 1.75rem;
      }

      label {
        display: block;
      }
    }
  }
}

.invite-user-form {
  .col-name,
  .col-email {
    min-width: 100%;
  }

  .col-permission {
    width: 100%;
  }

  .col-btn {
    max-width: 46px;
    min-width: 46px;
  }

  @media screen and (min-width: 375px) {
    .col-name,
    .col-email {
      min-width: 50%;
    }
  }

  @include media-breakpoint-up(sm) {
    .col-name,
    .col-permission {
      min-width: 28.631%;
    }

    .col-email {
      width: 100%; // 36.376%
      min-width: auto;
    }
  }
}
