.game-cat-info {
  color: $white;
}

.game-cat-pic {
  margin-right: 13px;
}

.game-cat-label {
  display: block;
}

.game-cat-value {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
}
