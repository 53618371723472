.infobar-item {
  color: $white;

  .infobar-title {
    display: block;
    margin-bottom: 0.25rem;
  }

  .progress-title,
  .infobar-value {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: $font-weight-bold;

    span {
      font-size: 0.75em;
    }
  }

  .progress-holder {
    margin-top: 0.75rem;
  }
}
