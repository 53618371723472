.table-bordered {
  > tbody > tr {
    border-top: 1px solid rgba($gray-light, 0.2);

    &:first-child {
      border-top: 0;
    }
  }

  th,
  td {
    @include media-breakpoint-up(md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.icon-payment {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 1.75rem;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  text-indent: -9999px;
  margin-right: 0.625rem;
  background-repeat: no-repeat;
  background-size: 2.5rem auto;
}

.link-payment {
  display: flex;
  align-items: center;
}

.icon_visa {
  background-image: $visa-card;
}

.icon_amex {
  background-image: $amex-card;
}

.icon_discover {
  background-image: $discover-card;
}

.icon_master {
  background-image: $master-card;
}

.table-banks {
  .bank-name {
    display: block;
  }

  .card-detail {
    display: block;
    font-style: inherit;
  }

  @include media-breakpoint-down(sm) {
    font-size: 0.8125rem;
    line-height: 1.5;

    .bank-name {
      font-size: 0.9375rem;
      margin-bottom: 0.25rem;
    }
  }
}
