.badge {
  line-height: 1.1667;
  margin-right: 0.375rem;

  &:last-child {
    margin-right: 0;
  }
}

.badge-warning {
  color: $gray-800;
}

.attr-list {
  margin-top: -0.375rem;

  .badge {
    margin-top: 0.375rem;
  }
}
