.popup-success {
  color: $white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0.875rem;
  line-height: 1.35714;
  letter-spacing: 0.02143em;
  font-weight: 700;
  text-align: center;
  transition: opacity $transition-speed-base $transition-function-base;
  background-color: rgba($navy-light, 0.9);
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  img,
  svg {
    vertical-align: top;
  }

  p {
    margin: 0.625rem 0 0;
  }

  .card.success & {
    opacity: 1;
    visibility: visible;
  }
}

.popup-holder {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $navy-dark;
}

.popup-flex {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
  background-color: $navy-dark;
}
