.app-header {
  background: $navy-light;
  border-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  min-width: $min-width-base;

  @include media-breakpoint-up(lg) {
    padding-left: 1.25rem;
    padding-right: 2.5rem;
  }

  .navbar-nav {
    padding-top: 0.125rem;
  }

  .dropdown-menu {
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: $border-radius;
  }

  .dropdown.nav-item {
    > a {
      position: relative;
      padding-right: 34px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 12px;
        height: 7px;
        margin-top: -2px;
        background-image: $arrow-btm;
        background-repeat: no-repeat;
      }
    }
  }

  .dropdown-item {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }

  .nav-item {
    button {
      min-width: auto;
    }
  }
}

.app-body .main {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;

  @include media-breakpoint-up(lg) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.main .container-fluid {
  height: 100%;

  @include media-breakpoint-down(xs) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.navbar-toggler {
  &:focus {
    outline: 1px dotted #fff;
    outline-offset: -1px;
  }
}
