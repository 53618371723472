.demographics-item {
  display: flex;
  color: $white;
  min-width: 7rem;
  max-width: 9.375rem;
  display: inline-block;
  vertical-align: top;
  margin-right: 1.75rem;

  &:last-child {
    margin-right: 0;
  }

  .dd-sm & {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.demographics-icon {
  float: left;
  margin-right: 0.875rem;

  + .text-after {
    overflow: hidden;
  }

  &.push-2 {
    margin-top: 0.75rem;
  }
}

.icon-male,
.icon-female {
  display: inline-block;
  vertical-align: top;
  width: 26px;
  height: 45px;
  overflow: hidden;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-male {
  background-image: $male-icon;
}

.icon-female {
  background-image: $female-icon;
}

.demographics-label {
  display: block;
  white-space: nowrap;
}

.demographics-value {
  display: block;
  font-size: 1.9375rem;
  line-height: 1;
  white-space: nowrap;
}
