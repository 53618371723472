.campaign-creation {
  position: relative;
  background-color: $gray-alt;
  padding: 2.5rem 1rem 1.25rem;

  .btn-back {
    position: absolute;
    top: 1rem;
    left: 1.25rem;

    @include media-breakpoint-up(sm) {
      top: 1.375rem;
      left: 1.6875rem;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 2.625rem 1.5rem 4rem;
  }

  @include media-breakpoint-up(md) {
    padding: 2.625rem 2rem 6rem;
  }
}

.heading {
  text-align: center;
  margin-bottom: 1rem;

  h2 {
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.3334;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  @include media-breakpoint-up(sm) {
    .popup-holder & {
      margin-bottom: 2rem;

      h2 {
        font-size: 1.5rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3.3125rem;
  }
}
