.col-promoted-item {
  width: 100%;
}

.promoted-link {
  @include media-breakpoint-up(md) {
    width: calc(99%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.row-actions {
  white-space: nowrap;
}

.col-auto {
  width: calc(100%);
}

.col-64 {
  width: 4rem; // 64px
}

.col-70 {
  width: 4.375rem; // 90px
}

.col-90 {
  width: 5.625rem; // 90px
}

.col-120 {
  width: 7.5rem; // 120px
}

.col-140 {
  width: 8.75rem; // 140px
}
