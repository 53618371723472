.order-details {
  color: $white;

  address {
    margin-bottom: 0;
  }
}

.order-name {
  display: block;
  padding: 0.4375rem 0;
}

.order-shipping {
  margin-bottom: 2.375rem;

  h6,
  .h6 {
    margin-bottom: 0.625rem;
  }

  p {
    margin-bottom: 0;
  }
}
