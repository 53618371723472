.scuti_img_input {
  border: 1px solid #53555b;
  border-radius: 4px;
}

.scuti_img_input_wrapper {
  cursor: pointer;
}

.scuti_img_input_wrapper_disabled {
  opacity: 0.5;
}

.scuti_img_input_error {
  border: 1px solid #f86c6b;
}

.file_input {
  visibility: hidden;
  height: 100%;
  width: 100%;
}

.new_img_icon {
  width: 10rem !important;
  height: 10rem !important;
  left: 50%;
  top: 50%;
  cursor: pointer;
}
