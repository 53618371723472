.currency-info {
  color: $white;
}

.currency-label {
  display: block;
}

.currency-value {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
}

.currency-note {
  color: $white;
  display: block;
  font-weight: bold;
  margin: 1rem 0 0;
}

.currency-details {
  margin-bottom: 2.5rem;
}

.currency-pic {
  $pic-size: 2.625rem;

  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  min-width: $pic-size;
  width: $pic-size;
  height: $pic-size;
  line-height: $pic-size;

  .no-pic-currency {
    display: block;
    height: 100%;
    background-image: $no-currency-icon;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    border-radius: 0.5rem;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    &:not[src^='.svg'],
    &:not[src*='.svg'] {
      border-radius: 0.5rem;
    }
  }
}

.currency-name {
  color: $white;
  width: calc(100% - 3.74rem);
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1.3334;
}
