.loader-banner {
  position: relative;
}

.text-add-banner {
  width: 100%;
  height: 5.625rem;
  position: relative;
  text-align: center;
  background-color: $gray-opt;
  border-radius: $border-radius;
  padding: 1rem;
  cursor: default;

  .text-help {
    max-width: 14rem;
    margin: 0 auto;
  }
}
