.pagination {
  button {
    font-size: .875rem;
    line-height: 1.1875rem;
    transition: color $transition-speed-base ease, background-color $transition-speed-base ease;

    &.btn-prev,
    &.btn-next {
      font-size: .9375rem;
    }
  }
}
