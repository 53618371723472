.input-range__slider {
  appearance: none;
  background: $navy-light;
  border: 1px solid $white;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  width: $input-range-dot-size;
  height: $input-range-dot-size;
  margin-left: calc(-#{$input-range-dot-size} / 2);
  margin-top: calc((-#{$input-range-dot-size} - #{$input-range-track-height}) / 2);
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;

  &:active {
    transform: scale(1.3);
  }

  &:focus {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.05);
  }

  .input-range--disabled & {
    background: #ccc;
    border: 1px solid #ccc;
    box-shadow: none;
    transform: none;
  }
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: $white;
  transform: translateZ(0);
  white-space: nowrap;
  opacity: 0.5;
}

.input-range__label--min,
.input-range__label--max {
  top: calc(#{$input-range-dot-size} + 0.25rem);
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: 100%;
  margin-top: 1rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: $range-bar-color;
  border-radius: $range-bar-border-radius;
  cursor: pointer;
  display: block;
  height: $input-range-track-height;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;

  .input-range--disabled & {
    background: #eeeeee;
  }
}

.input-range__track--background {
  left: 0;
  position: absolute;
  right: 0;
  top: calc(#{$input-range-dot-size - $input-range-track-height} / 2);
}

.input-range__track--active {
  background: $white;
}

.input-range {
  height: $input-range-height;
  position: relative;
  width: 100%;
  margin-bottom: 1.4375rem;
}
