@import '@scss/vendors/_variables';
@import '@scss/_variables';

.page_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page_header {
  // margin-bottom: rem(30);
}

.page_content {
  height: 100%;
}

.page_footer {
  margin-top: rem(30);
  margin-bottom: rem(20);
}
