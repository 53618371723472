.loader-details {
  .text-danger {
    font-size: 0.75rem;
    line-height: 1.2;
  }
}

.loader-banner {
  position: relative;

  .btn,
  img,
  .text-add-banner {
    border-radius: $border-radius-lg;
  }

  &:hover {
    .btn {
      opacity: 1;
      visibility: visible;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.loader-details {
  margin-top: 1rem;
  margin-bottom: 1.1875rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.loader-details_info {
  color: $white;
  overflow: hidden;
  max-width: 14rem;
  margin-right: 1.125rem;
  margin-left: 1.125rem;
  cursor: default;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  p {
    font-size: 0.75rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}

.icon-image-add {
  $icon-size: 0.75rem;

  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  border: 2px solid $white;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    background-color: $white;
    transform: translate(-50%, -50%);
  }

  &::before {
    width: $icon-size;
    height: 0.125rem;
  }

  &::after {
    width: 0.125rem;
    height: $icon-size;
  }
}

// on Edit game
.thumb-holder {
  text-align: right;

  .loader-details_img {
    display: inline-block;
    vertical-align: top;
  }

  .thumb-holder-note {
    padding-top: 4px;
    margin-bottom: 0;
  }
}
