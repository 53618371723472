$color-accent: #0441f2; // #008489
$color-header: $navy-dark;
$picker-drop-bg: #fff; // #fff
$picker-month-bg: #fff; // #fff
$picker-text-color: #7c86a2; // 757575
$picker-text-color-disabled: #e1e4e7;
$picker-date-bg: $picker-month-bg;
$picker-date-bg-hover: $color-accent;
$picker-border-color: $white; // #e4e7e7
$picker-btn-color: $white; // #757575
$picker-btn-bg: $color-accent;
$picker-btn-bg-hover: #004cd9;
$picker-btn-border-color: $picker-btn-bg;
$picker-btn-border-color-hover: $picker-btn-bg-hover;

$picker-date-hover: $color-accent; // 00a699
$picker-date-hover-light: rgba($color-accent, 0.1); // #b2f1ec
$picker-date-active: $color-accent; // #80e8e0
$picker-date-selected: $picker-date-hover-light; // #66e2da
$picker-date-selected-hover: $picker-date-hover; // #33dacd

.DateInput {
  background: transparent; // #fff
}
.DateInput__disabled {
  background: transparent; // #f2f2f2
  color: inherit; // #dbdbdb
}
.DateInput_input {
  // color: #484848;
  // background-color: $white;
  // border-bottom-color: transparent;
}
.DateInput_input__focused {
  // background: $white;
  // border-bottom-color: #008489;
}
.DateInput_input__disabled {
  background: #f2f2f2;
}
.DateInput_fangShape {
  fill: $picker-drop-bg;
}
.DateInput_fangStroke {
  stroke: $picker-drop-bg;
  fill: transparent;
}
.DateRangePickerInput {
  background-color: transparent; // #fff
}
.DateRangePickerInput__disabled {
  background: transparent; // #f2f2f2
}
.DateRangePickerInput__withBorder {
  // border: 1px solid #dbdbdb;
  border: none;
}
.DateRangePickerInput_arrow {
  color: $picker-text-color;
}
.DateRangePickerInput_arrow_svg {
  fill: $white;
}
.DateRangePickerInput_clearDates {
  color: inherit;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
}
.DateRangePickerInput_calendarIcon {
  color: inherit;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: $white;
}
.DateRangePicker_picker {
  z-index: 1000;
  background-color: $white;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: $white;
}
.DateRangePicker_closeButton {
  color: inherit;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
}
.DateRangePicker_closeButton_svg {
  fill: #cacccd;
}

// styles

.DateRangePicker {
  display: block;
}

.DateRangePickerInput {
  background: transparent;
  width: 100%;

  &_1 {
    border: 0;
    border-radius: 0;
  }
}

.DateInput {
  position: relative;
  width: calc((100% - 1.5rem) / 2);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0.625rem;
    width: 1.125rem;
    height: 1.125rem;
    margin-top: -0.625rem;
    overflow: hidden;
    background-image: $calendar-icon;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xs) {
      right: 0.3rem;
      width: 1rem;
      height: 1rem;
      margin-top: -0.5625rem;
      background-size: contain;
      background-position: 50% 50%;
    }

    @include media-breakpoint-up(xl) {
      right: 0.25rem;
    }
  }

  &__disabled {
    &,
    & + .DateRangePickerInput_arrow {
      opacity: 0.25;
    }
  }

  @include media-breakpoint-up(xl) {
    width: calc((100% - 1rem) / 2);
  }

  @include media-breakpoint-up(xxl) {
    width: calc((100% - 1.5rem) / 2);
  }
}

.DateInput_input {
  border-radius: $border-radius-md;

  @extend .form-control;

  padding-right: 1.4rem;

  .bg-custom.card & {
    @extend .input-sm;
    @extend .bg-light;
  }

  &__disabled {
    font-style: inherit;
  }

  &.DateInput_input__small {
    height: 2.1875rem;
    line-height: 1.0625;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  @include media-breakpoint-up(sm) {
    padding-right: 2rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 1.6rem;
  }

  @include media-breakpoint-up(xxl) {
    padding-right: 2rem;
  }
}

.DateRangePickerInput_arrow {
  color: $white;
  margin: 0 0.5rem;
  display: inline-block;
  vertical-align: middle;
  line-height: 2.75rem;

  .DateInput__small ~ & {
    line-height: 2.1875rem;
  }

  &::before {
    content: '';
    width: 0.5rem;
    height: 1px;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    background-color: $white;
  }

  svg {
    display: none;
  }

  .topbar & {
    &::before {
      display: none;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border-color: $color-accent;
  background-color: $color-accent;
}

.CalendarDay__selected_span {
  background-color: $color-accent;
  // border-color: $color-accent;
  transition: background-color $transition-speed-base $transition-function-base,
    border-color $transition-speed-base $transition-function-base;

  &:active,
  &:hover {
    background-color: $color-accent;
    border-color: $color-accent;
  }
}

// .DayPickerKeyboardShortcuts_show__bottomRight::before {
//   border-right-color: $color-accent;
// }

.DateInput_fang {
  margin-top: 1px;
}

// colors

.PresetDateRangePicker_button {
  border-color: $picker-date-hover;
  color: $picker-date-hover;
}
.PresetDateRangePicker_button__selected {
  color: $white;
  background: $picker-date-hover;
}
.SingleDatePickerInput {
  background-color: $white;
}
.SingleDatePickerInput__withBorder {
  border-color: #dbdbdb;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
}
.SingleDatePicker_picker {
  background-color: $white;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: $white;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
}
.SingleDatePicker_closeButton_svg {
  fill: #cacccd;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before,
.DayPickerKeyboardShortcuts_show__topRight::before,
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right-color: $color-accent;
}
.DayPickerKeyboardShortcuts_show__topLeft::before,
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left-color: $color-accent;
}
.DayPickerKeyboardShortcuts_showSpan {
  color: $white;
}
.DayPickerKeyboardShortcuts_panel {
  background: $white;
  border-color: #dbdbdb;
}
.DayPickerKeyboardShortcuts_closeSvg {
  fill: $picker-text-color; // #cacccd
}
button:hover,
button:focus {
  .DayPickerKeyboardShortcuts_closeSvg {
    fill: $color-header;
  }
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.CalendarDay__default {
  border: none !important;
  // border-color: $picker-border-color;
  color: $picker-text-color;
  background: $picker-date-bg;
}
.CalendarDay__default:hover {
  background: $color-accent;
  // border-color: $color-accent;
  color: $white;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  // border-color: $picker-border-color;
  color: inherit;
}
.CalendarDay__outside {
  background: $white;
  color: $picker-text-color;
}
.CalendarDay__blocked_minimum_nights {
  background: $white;
  // border-color: #eceeee;
  color: #cacccd;
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: $white;
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: $picker-text-color;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: $picker-text-color;
}
.CalendarDay__selected_span {
  background: $picker-date-selected;
  // border-color: $color-accent;
  // color: $white;
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $picker-date-selected-hover;
  // border-color: $picker-date-selected-hover;
  color: $white;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $picker-date-hover;
  // border-color: $picker-date-hover;
  color: $white;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $picker-date-hover-light;
  // border-color: $color-accent;
  color: inherit;
}
.CalendarDay__hovered_span:active {
  background: $picker-date-active;
  // border-color: $picker-date-active;
  color: $white;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  // border-color: #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  // background: $picker-date-bg;
  // border-color: $picker-border-color;
  color: $picker-text-color-disabled;
  // opacity: 0.6;
}
.CalendarDay__hovered_start_first_possible_end {
  background: #f00;
  // border-color: #eceeee;
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border-color: $picker-border-color;
}
.CalendarMonth_caption {
  color: $color-header;
}
.DayPicker,
.DateRangePicker_picker,
.CalendarMonthGrid,
.DayPicker__horizontal,
.DayPicker_transitionContainer {
  // border-radius: 0;
  background: $picker-drop-bg;
}
.CalendarMonth {
  background: $picker-month-bg;
}
.DayPicker__withBorder {
  border-radius: 0;
}
.DayPickerNavigation_button__default {
  border-color: $picker-btn-border-color;
  background-color: $picker-btn-bg;
  color: $picker-btn-color;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border-color: $picker-btn-bg-hover;
}
.DayPickerNavigation_button__default:active {
  background: $picker-btn-bg-hover;
}
.DayPickerNavigation_button__disabled {
  opacity: 0.25;
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  opacity: 0.25;
}
.DayPickerNavigation_button__verticalDefault {
  background: $picker-btn-bg;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.DayPickerNavigation_svg__horizontal {
  fill: $picker-btn-color;
}
.DayPickerNavigation_svg__vertical {
  fill: $picker-btn-color;
}
.DayPickerNavigation_svg__disabled {
  opacity: 0.25;
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
}
.DayPicker_weekHeader,
.DayPickerKeyboardShortcuts_title,
.KeyboardShortcutRow_key {
  color: $color-header;
}
.DayPicker_weekHeader__verticalScrollable {
  // border-bottom-color: #dbdbdb;
  // background: $white;
}

.DayPickerKeyboardShortcuts_list {
  li {
    margin-top: 0.5rem;
  }
}
