.custom-button-plan {
  display: block;
  position: relative;
  padding: 2.5rem 0.25rem 0.5rem;
  font-size: calc(100vw / 22);
  line-height: 1;
  overflow: hidden;
  margin-bottom: 0.5rem;
  text-align: center;

  *,
  *::before,
  *::after {
    cursor: default;
  }

  .custom-control-label {
    z-index: 10;
    color: $body-color;
    font-size: calc(100vw / 22);
    line-height: 1.3334;
    font-weight: $font-weight-bold;
    display: block;

    &::before {
      z-index: 100;
      top: -2.5rem;
      bottom: -10rem;
      left: -5rem;
      right: -5px;
      width: auto;
      height: auto;
      border: 0;
      opacity: 0;
      cursor: pointer;
    }

    &::after {
      display: none;
    }
  }

  .custom-button-details {
    padding-top: 0.25rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $gray-opt;
      border-radius: $border-radius-lg;
      transition: background-color $transition-speed-base $transition-function-base;
    }
  }

  .custom-button-price {
    color: $white;
    position: relative;
    font-weight: $font-weight-bold;
  }

  .custom-button-period {
    color: $body-color;
    position: relative;
    font-size: 0.5em;
    letter-spacing: 0.0625rem;
  }

  .custom-button-icon {
    $icon-size: 1.5rem;

    width: $icon-size;
    height: $icon-size;
    top: 0.75rem;
    left: 50%;
    margin-left: -$icon-size / 2;
    position: absolute;
    z-index: 10;
    border: 1px solid rgba($white, 0.5);
    transition: background-color $transition-speed-base $transition-function-base,
      border-color $transition-speed-base $transition-function-base;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.125rem;
      height: 0.8125rem;
      margin: -0.375rem 0 0 -0.5rem;
      overflow: hidden;
      background-image: $custom-checkbox-indicator-icon-checked;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 75% 75%;
      transition: opacity $transition-speed-base $transition-function-base;
      opacity: 0;
    }
  }

  .custom-control-input {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked + label.custom-control-label {
      color: $blue;
    }

    &:checked + label + .custom-button-details {
      &::before {
        background-color: $white;
      }

      .custom-button-price {
        color: $navy-dark;
      }

      .custom-button-icon {
        background-color: $blue;
        border-color: $blue;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &:hover .custom-button-details::before {
    background-color: $navy-light;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: inherit;
  }

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
    padding: 0.75rem 1.125rem 1rem 4.125rem;
    text-align: left;

    .custom-control-label {
      font-size: 1.125rem;
    }

    .custom-button-icon {
      $icon-size: 1.875rem;

      width: $icon-size;
      height: $icon-size;
      top: 50%;
      left: 1.125rem;
      margin-top: -$icon-size / 2;
      margin-left: 0;

      &::after {
        background-position: 0 0;
        background-size: auto auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 1.125rem;
    padding-bottom: 1.5625rem;
  }
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: inherit;
  opacity: 0.5;
}
