.product-list {
  margin: -1.25rem 0;
  padding: 0;
  list-style: none;
}

.product-list-item {
  z-index: 1;
  font-size: .875rem;
  line-height: 1.35714;
  border-top: 1px solid rgba($gray-light, .2);

  &:first-child {
    border-top: 0;
  }

  .product-name {
    display: block;
  }
}

.product-list-link {
  position: relative;
  display: block;
  font-weight: normal;
  padding: 1.25rem 0;
  overflow: hidden;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: .75rem;
    bottom: .75rem;
    left: -.75rem;
    right: -.75rem;
    transition: opacity $transition-speed-base $transition-function-base;
    background-color: $navy-light;
    opacity: 0;
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 1;
    }
  }
}

.product-list-pic {
  float: left;
  width: 3.75rem;
  height: 3.75rem;
  padding: .25rem;
  border-radius: $border-radius;
  background: $white;
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.product-list-body {
  overflow: hidden;
  padding-top: .0625rem;
}
