.product-details {
  color: $white;
  line-height: 1.35714;

  h2 {
    font-size: 1.125rem;
    line-height: 1.38889;
    margin-bottom: 0.8125rem;
  }

  h3 {
    font-size: 0.875rem;
    line-height: 1.35714;
    margin-bottom: 0.7143em;
  }

  .row + .row {
    margin-top: 2.125rem;
  }

  @include media-breakpoint-down(sm) {
    .row > div + div {
      margin-top: 2.125rem;
    }
  }

  @include media-breakpoint-up(md) {
    .col-copy {
      padding-right: 2.625rem;
    }

    .col-tags {
      padding-left: 2.25rem;
    }
  }
}

.product-imgs {
  min-height: 13.0625rem;
  position: relative;
  margin-top: -0.8125rem;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  padding-left: 13.25rem;
}

.product-img {
  display: inline-block;
  vertical-align: top;
  width: 3.75rem;
  height: 3.75rem;
  padding: 0.25rem;
  line-height: 3.4rem;
  background-color: $white;
  border-radius: $border-radius-md;
  margin-right: 0.8125rem;
  margin-top: 0.8125rem;
  font-size: 0.75rem;

  &:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 12.25rem;
    height: 12rem;
    padding: 1rem;
    line-height: 10rem;
    margin-right: 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    vertical-align: middle;
  }
}

.product-category {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3889;
  text-transform: capitalize;
}
