.features-list {
  margin: 0 0 0.5rem;
  padding: 0;
  list-style: none;

  @include media-breakpoint-down(xs) {
    border-bottom: 1px solid $gray-opt;
  }

  li {
    width: 100%;
    align-items: center;
    display: flex;
    border-top: 1px solid $gray-opt;
    padding: 0.5rem 0;

    &:first-child {
      border-top: 0;
    }
  }

  .title {
    width: 70%;
  }

  .icon-holder {
    width: 30%;
  }

  @include media-breakpoint-up(sm) {
    .title,
    .icon-holder {
      width: 50%;
    }
  }

  .icon-holder {
    text-align: center;
  }

  .icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 0.6875rem;
    height: 0.5625rem;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 0;
      transition: opacity $transition-speed-base $transition-function-base;
    }

    &::before {
      background-image: $unchecked-icon;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      background-image: $checked-icon;
      opacity: 0;
    }
  }

  li.checked .icon {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}
