.switcher {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
}

.switcher-cta {
  position: relative;
  background-color: $gray-alt;
  border-radius: $border-radius;
  padding: 0.25rem 1.875rem 0.25rem 0.25rem;
  transition: background-color $transition-speed-base $transition-function-base;

  &:hover {
    background-color: $secondary;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 10px;
    height: 5px;
    margin-top: -2px;
    background-image: $arrow-switcher;
    background-repeat: no-repeat;
    // border-top-color: $switcher-arrow-color;
  }
}

.switcher-list {
  width: 250px;
  height: 300px;
  overflow-y: auto;
}

.switcher-pic,
.switcher-item-pic {
  $pic-size: 2.25rem;

  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
  min-width: $pic-size;
  width: $pic-size;
  height: $pic-size;
  line-height: $pic-size;

  .no-game-img {
    display: block;
    height: 100%;
    background-image: $no-game-icon;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    border-radius: 0.25rem;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    &:not[src^='.svg'],
    &:not[src*='.svg'] {
      border-radius: $border-radius;
    }
  }

  .switcher-drop & {
    margin-right: 0.8125rem;
  }

  .switcher-curitem {
    margin-left: auto;
    margin-right: auto;
  }
}

.pic-lg {
  $pic-size: 5rem;

  width: $pic-size;
  height: $pic-size;
  line-height: $pic-size;
  margin-right: 0;
  margin-bottom: 0.625rem;

  &:not[src^='.svg'],
  &:not[src*='.svg'] {
    border-radius: $border-radius-lg;
  }

  .no-game-img {
    border-radius: 0.5rem;
  }
}

.switcher-label {
  color: $white;
  font-weight: $font-weight-bold;
  display: inline-block;
  vertical-align: middle;
}

.switcher-drop {
  z-index: 100;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: $gray-alt;
  border: 1px solid $gray-opt;
  border-radius: $border-radius;
  padding: 2rem 1.375rem;
  text-align: left;
  max-width: 300px;
  margin-top: 0.1875rem;

  .btn {
    margin-top: 1.25rem;
  }
}

.switcher-curitem {
  text-align: center;
  margin-bottom: 1.25rem;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1.5;
}

.switcher-curitem-title {
  color: $white;
  font-size: 1.5rem;
  line-height: 1.33334;
  display: block;
}

.switcher-curitem-info {
  font-style: inherit;
}

.switcher-item {
  color: $body-color;
  display: flex;
  margin-top: 0.625rem;
  padding: 0.375rem;
  background-color: $gray-opt;
  border-radius: $border-radius-lg;
  font-size: 0.75rem;
  line-height: 1.5;
  transition: background-color $transition-speed-base $transition-function-base;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: $switcher-item-bg-hover;
  }
}

.switcher-item-heading {
  overflow: hidden;
}

.switcher-item-title {
  color: $white;
  display: block;
  font-size: 0.875rem;
}

.switcher-item-info {
  font-style: inherit;
}
