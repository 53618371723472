.card {
  font-size: 0.875rem;
  line-height: 1.4286;

  .card-header {
    font-style: inherit;
    line-height: inherit;
    font-weight: bold;
    letter-spacing: 0.01875em;

    &:first-child {
      border-radius: $card-border-radius $card-border-radius 0 0;
    }

    .view-all {
      float: right;
    }

    h2,
    h3 {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
    }

    h2 {
      font-size: 1.125rem;
      line-height: 1.3889;
    }

    h3 {
      font-style: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
    }
  }

  .card-footer {
    &:last-child {
      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  .view-all + h3 {
    overflow: hidden;
    margin-right: 0.5rem;
  }

  h1 {
    color: $white;
    margin-bottom: 0.625rem;
  }

  &.bg-custom {
    &,
    .card-header.bg-custom {
      background: $card-bg-custom;
    }

    .card-header.bg-custom {
      padding-bottom: 0;
    }

    .card-header {
      color: $card-cap-color-custom;
      background: $card-cap-bg-custom;
    }

    .card-footer {
      background: transparent;
    }

    .border-top {
      border-color: $gray-light !important;
    }
  }

  .same-height & {
    height: calc(100% - #{$spacer * 1.5});
  }

  .same-height.gap-card & {
    height: calc(100% - 0.75rem);

    @include media-breakpoint-up(sm) {
      height: calc(100% - #{$card-columns-gap});
    }
  }

  &.br-xl,
  .card-br-xl & {
    border-radius: $border-radius-xl;

    .card-header:first-child {
      border-radius: $border-radius-xl $border-radius-xl 0 0;
    }

    .card-footer:last-child {
      border-radius: $border-radius-xl $border-radius-xl 0 0;
    }
  }
}

.icon {
  svg {
    vertical-align: top;
  }
}

.card-headline {
  font-size: 0.75rem;
  line-height: 1.3335;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 2rem;
  }
}

.w-23 .card-body {
  padding: 2.25rem 1.375rem;
}

.card-header-actions {
  margin-top: -0.3125rem; // -0.125rem
  margin-bottom: -0.3125rem; // -0.125rem
}

.card-footer {
  padding-top: $card-spacer-x;
  padding-bottom: $card-spacer-x;
}
