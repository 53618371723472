.popup-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($navy-dark, 0.8);
  padding: 1rem;

  h4 {
    font-size: 0.875rem;
    line-height: 1.4286;
    margin-bottom: 0.82125rem;
  }

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }
}

.popup {
  width: 100%;
  position: relative;
  background-color: $gray-alt;
  border-radius: $border-radius;
  overflow: hidden;
  padding: 1.2rem;
  font-size: 0.75rem;
  line-height: 1.5;

  h3 {
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    padding: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    padding: 2.625rem 2.5rem 2.5rem;
    min-width: 47.375rem;
    width: auto;
  }
}

.popup-title {
  text-align: center;
  margin-bottom: 1.25rem;

  h3 {
    font-size: 1.125rem;
    line-height: 1.3335;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 2.25rem;

    h3 {
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 3.3125rem;
  }
}

.steps-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  overflow: hidden;

  .line {
    height: 100%;
    background-color: $white;
  }
}

.popup-actions {
  padding-top: 1rem;

  button {
    margin-left: 1.125rem;
  }

  .btn-primary {
    min-width: 6rem;

    @include media-breakpoint-up(sm) {
      min-width: 7.8125rem;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: 1.1875rem;
  }
}
