$topbar-gap: 0.75rem;

.topbar {
  .page-title,
  .form-group {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;

    &:last-child {
      margin-bottom: 0;
      padding-right: 0;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 1.375rem;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .col-heading {
    position: relative;

    > a:first-child {
      position: absolute;
      top: 0;
      left: 0;
    }

    a + .page-title,
    .btn-back + .page-title,
    .btn-back + .form-group {
      padding-left: 3.6rem;
    }

    .btn {
      margin-bottom: 0.5rem;
    }
  }

  .col-heading {
    width: calc(100% - #{$topbar-gap});
  }

  .btn + .btn {
    margin-left: 0.625rem;
  }

  .btn-edit-holder {
    white-space: nowrap;
    vertical-align: top;
    padding-left: 1.5625rem;
  }

  @include media-breakpoint-down(md) {
    margin-left: -$topbar-gap / 2;
    margin-right: -$topbar-gap / 2;
    padding-bottom: $topbar-gap;

    .col-heading,
    .col-search,
    // .col-select,
    .col-action,
    .col-filter-btns {
      margin-left: $topbar-gap / 2;
      margin-right: $topbar-gap / 2;
      margin-bottom: 1rem;
    }

    .cols-tools {
      display: flex;
    }

    .col-search,
    .col-action {
      width: calc(100% - #{$topbar-gap}); // 100%
    }

    .col-action {
      text-align: right;

      .btn.d-block {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-left: -$topbar-gap / 2;
    margin-right: -$topbar-gap / 2;

    .cols-tools {
      flex-wrap: wrap;
    }

    .col-search {
      min-width: calc(100% - #{$topbar-gap});
    }

    // .col-select {
    //   min-width: calc(100% - 160px - #{$topbar-gap} * 2);
    // }

    .page-title + .col-search {
      display: inline-block;
      vertical-align: top;
    }
  }

  @include media-breakpoint-down(xs) {
    .col-select {
      button {
        width: 100%;
      }
    }

    .col-action {
      > .btn-primary {
        width: calc(60% - 0.625rem);
      }

      > .btn-outline-secondary {
        width: 40%;
      }
    }

    // .page-title {
    //   width: calc(100% - 3.8rem);
    // }
  }

  @include media-breakpoint-up(md) {
    .col-action {
      white-space: nowrap;

      > .btn-primary {
        min-width: 160px;
      }

      > .btn-outline-secondary {
        min-width: 113px;
      }
    }

    .form-group {
      width: 70%;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: -$topbar-gap / 2;
    margin-right: -$topbar-gap / 2;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 1.5625rem;

    .col-heading {
      margin-bottom: 0;
    }

    .col-heading,
    .col-search,
    // .col-select,
    .col-action {
      padding-left: $topbar-gap / 2;
      padding-right: $topbar-gap / 2;
    }

    .cols-tools {
      width: 64.035%;
      display: flex;
    }

    .col-search {
      width: 100%;
    }
  }
}

.mb-topbar {
  margin-bottom: 1.5625rem;

  @include media-breakpoint-down(md) {
    margin-bottom: $topbar-gap;
  }
}

.text-right {
  nav {
    display: inline-block;
  }
}

.col-search,
.col-filter-btns {
  .dropdown {
    display: inline-block;
    vertical-align: top;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    @include media-breakpoint-down(md) {
      .dropdown {
        button {
          width: 100%;
          display: block;
        }
      }

      .dropdown-toggle {
        padding-left: $navbar-toggler-padding-x;
        padding-right: $navbar-toggler-padding-x;
        text-align: left;
      }
    }
  }

  .dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }
}

.col-search .dropdown {
  @include media-breakpoint-down(md) {
    width: 22%;
    min-width: 80px;
  }

  @include media-breakpoint-down(sm) {
    width: 40%;
  }
}

.col-filter-btns {
  width: calc(100% - #{$topbar-gap});
  text-align: right;

  .btn-group {
    margin-bottom: 0.75rem;

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin-bottom: 0.625rem;
    }

    .btn {
      min-width: 2.875rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      background-color: $secondary;

      &.active,
      &:hover,
      &:focus {
        color: $pagination-active-color !important;
        background-color: $pagination-active-bg !important;
      }

      & + .btn {
        border-left: 0;
        margin-left: 1px;
      }
    }
  }
}

// order page
.topbar--alt {
  .col-heading {
    display: flex;
  }

  .mr-3 {
    margin-right: 0 !important;
  }

  .col-heading,
  .period-selector,
  .btn-group,
  .btn-toolbar {
    margin-bottom: $topbar-gap;
  }

  .page-title,
  .col-filter-btns,
  .btn-group {
    margin-bottom: 0;
  }

  .col-search-alt {
    // width: 33rem;
    display: block;
    min-width: auto;
    margin: 0;
    width: 100%;
  }

  > .col-search-alt {
    width: auto;
    margin-bottom: $topbar-gap;
    padding-left: $topbar-gap / 2;
    padding-right: $topbar-gap / 2;
  }

  .DateRangePickerInput {
    align-items: flex-start;
    display: flex;
  }

  .DateRangePicker {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    .period-selector {
      align-items: flex-start;
      display: flex;
    }

    .col-filter-btns {
      .btn-group {
        margin-right: $topbar-gap;
      }
    }

    .btn-toolbar {
      margin-bottom: 0;
    }

    .btn-toolbar {
      margin-right: $topbar-gap;
    }
  }

  @include media-breakpoint-up(lg) {
    display: block;

    .col-filter-btns {
      width: 100%;
      justify-content: flex-end;
    }

    .period-selector,
    .col-select-btn {
      padding-left: $topbar-gap / 2;
      padding-right: $topbar-gap / 2;
    }

    .col-heading {
      width: auto;
    }

    .DateInput__small {
      // width: 6.625rem;
    }
  }

  @include media-breakpoint-up(xl) {
    &,
    .col-filter-btns {
      display: flex;
    }

    .col-select-btn {
      width: 35%;
    }

    .col-heading {
      width: 40%;
    }

    .DateInput__small {
      width: 6.625rem;
    }
  }

  @include media-breakpoint-up(xxl) {
    .DateInput__small {
      width: 8rem;
    }
  }
}

.col-select-btn {
  align-items: flex-start;
  display: flex;

  margin-left: -$topbar-gap / 2;
  margin-right: -$topbar-gap / 2;

  > div {
    padding-left: $topbar-gap / 2;
    padding-right: $topbar-gap / 2;
  }

  .react-select {
    width: 70%;
  }

  .col-btn-alt {
    width: 30%;
  }

  .btn {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    .react-select {
      width: 60%;
    }

    .col-btn-alt {
      width: 40%;
    }
  }
}
