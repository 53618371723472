.budget-details {
  color: $white;
  font-weight: $font-weight-bold;

  .form-group + & {
    padding-top: 0.375rem;
  }
}

.budget-label {
  display: block;
  letter-spacing: 0.021428em;
  margin-bottom: 0.625rem;
}

.budget-price {
  font-size: 1.125rem;
  line-height: 1.33334;
  font-style: inherit;
}
