.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 58px;
  height: 58px;
  margin: -29px 0 0 -29px;
}

.circle {
  position: absolute;
  background-color: $white;
  width: 14px;
  height: 14px;
  border-radius: 9px;
  animation-name: circular-animation;
  animation-duration: 1.1s;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

.circle_1 {
  left: 0;
  top: 23px;
  animation-delay: 0.41s;
}

.circle_2 {
  left: 6px;
  top: 6px;
  animation-delay: 0.55s;
}

.circle_3 {
  top: 0;
  left: 23px;
  animation-delay: 0.69s;
}

.circle_4 {
  right: 6px;
  top: 6px;
  animation-delay: 0.83s;
}

.circle_5 {
  right: 0;
  top: 23px;
  animation-delay: 0.97s;
}

.circle_6 {
  right: 6px;
  bottom: 6px;
  animation-delay: 1.1s;
}

.circle_7 {
  left: 23px;
  bottom: 0;
  animation-delay: 1.24s;
}

.circle_8 {
  left: 6px;
  bottom: 6px;
  animation-delay: 1.38s;
}

@keyframes circular-animation {
  0%{
    transform: scale(1);
  }

  100%{
    transform: scale(.3);
  }
}
