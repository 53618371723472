body,
.non-auth-container {
  min-width: $min-width-base;
}

h1,
.h1 {
  font-size: $font-size-h1;
}

h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.3334;
}

h6,
.h6 {
  font-size: $font-size-h6;
  margin-top: 1.875rem;
  margin-bottom: 1.125rem;
}
