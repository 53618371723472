.MuiPaginationItem-root.MuiPaginationItem-outlined {
  color: #fff;
  border-color: rgb(64, 66, 73);
}

.MuiPaginationItem-root.MuiPaginationItem-outlined:hover {
  color: #000;
  background: #fff;
  border-color: #000;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined:focus {
  outline: none;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected {
  color: #000;
  background: #fff;
}

.MuiPaginationItem-root.MuiPaginationItem-outlined.Mui-selected:hover {
  color: #000;
  background: #fff;
}
