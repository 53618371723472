.sidebar {
  border-right: 0;

  .nav-item {
    font-size: .875rem;
    line-height: 1.5;
  }

  .nav-link {
    color: #fff;
    opacity: .5;
    transition: color $transition-speed-base $transition-function-base, background-color $transition-speed-base $transition-function-base, opacity $transition-speed-base $transition-function-base;

    path {
      fill: #ffffff;
      transition: fill $transition-speed-base $transition-function-base, background-color $transition-speed-base $transition-function-base;
    }

    .ico-settings {
      fill: none;
    }

    &:hover,
    &:focus,
    &.active {
      background: #2a2c34;
      opacity: 1;

      .ico-dashboard {
        fill: #63c2de;
      }

      .ico-shops {
        fill: #f29fbd;
      }

      .ico-games {
        fill: #45a164;
      }

      .ico-orders {
        fill: #0059ff;
      }

      .ico-campaign {
        fill: #20a8d8;
      }

      .ico-affiliate {
        fill: #f86c6b;
      }

      .ico-finance {
        fill: #63c2de;
      }

      .ico-users {
        fill: #0059ff;
      }

      .ico-settings {
        fill: none;
        opacity: 1;
      }

      .ico-products {
        fill: #f29fbd;
      }
    }
  }
}

.ico-holder {
  display: inline-block;
  vertical-align: 3px;
  width: 23px;
  margin-right: 3px;
  text-align: center;
}
