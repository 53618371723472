.age-info {
  color: $white;
}

.age-label {
  display: block;
}

.age-value {
  display: block;
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: bold;
}

.age-note {
  color: $white;
  display: block;
  font-weight: bold;
  margin: 1rem 0 0;
}

.age-details {
  margin-bottom: 2.5rem;
}
