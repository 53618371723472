.react-select {
  text-align: left;

  .react-select__control {
    @extend .form-control;
    padding: 0;
    height: auto;
    min-height: $input-height;
    display: flex;
  }

  &.is-invalid {
    & + .invalid-feedback {
      display: block;
    }

    .react-select__control {
      border-color: $red !important;
    }
  }

  &.is-valid .react-select__control {
    border-color: $green !important;
  }

  .react-select__control--menu-is-open {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  .react-select__value-container {
  }

  .react-select__value-container--has-value .react-select__single-value {
    color: $white;
  }

  &.select_input-sm {
    .react-select__control {
      min-height: $input-height-sm;
    }

    .react-select__value-container {
      line-height: 1.214285;
    }
  }

  &.select_bg-light {
    .react-select__control {
      @extend .bg-light;
    }
  }

  &.select_bg-opt {
    .react-select__control {
      @extend .bg-opt;
    }

    .react-select__control--menu-is-open {
      border-color: $gray-opt;
    }
  }

  .react-select__indicators {
    position: relative;
    width: 1.625rem;
    background-color: transparent;

    svg {
      display: none;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    position: absolute;
    width: 100%;
    height: 0.25rem;
    padding: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      border-width: 0.3125rem 0.25rem;
      border-style: solid;
      margin-left: -0.25rem;
    }

    &::before {
      bottom: 100%;
      border-color: transparent transparent $select-arrow-color transparent;
    }

    &::after {
      top: 100%;
      border-color: $select-arrow-color transparent transparent;
    }
  }

  .react-select__menu {
    margin-top: 0;
    margin-bottom: 0;
    box-shadow: none;
    background: $navy-light;
    border: 1px solid $gray-opt;
    border-radius: 0 0 $border-radius $border-radius;
  }

  .react-select__menu-list {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .react-select__option {
    color: $white;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    line-height: 1.42857;
    background: $navy-light;
  }

  .react-select__option--is-focused {
    background: $gray-alt !important;
  }

  .react-select__option--is-selected {
    background: $gray-alt;
  }

  .react-select__menu-list--is-multi {
    .react-select__option {
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        right: 0.8125rem;
        bottom: 0.5rem;
        width: 1rem;
        height: 1rem;
      }

      &::before {
        border-radius: $custom-checkbox-indicator-border-radius;
        border: 1px solid $gray-light;
        transition: border-color $transition-speed-base $transition-function-base,
          background-color $transition-speed-base $transition-function-base;
      }

      &::after {
        background-image: $custom-checkbox-indicator-icon-checked;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 50% 50%;
        opacity: 0;
        transition: opacity $transition-speed-base $transition-function-base;
      }

      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        &::before {
          background-color: $blue;
          border-color: $blue;
        }

        &::after {
          opacity: 1;
        }
      }
    }
  }

  .react-select__multi-value__remove {
    transition: opacity $transition-speed-base $transition-function-base;
    // opacity: 0.5;

    svg {
      fill: $white;
    }

    &:hover {
      opacity: 1;
      background-color: transparent;
    }
  }

  .react-select__value-container--is-multi {
    padding-top: 0.25rem;
    padding-bottom: 0.1875rem;
  }

  .react-select__multi-value {
    background-color: $blue;
    margin: 0.1875rem 0.125rem;
  }

  .react-select__multi-value__label {
    color: $white;
    font-size: 0.75rem;
    line-height: 1;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    // line-height: 1.25;
  }
}
