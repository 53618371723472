.img_hovered {
  opacity: 0.3;
}

.img_disabled {
  opacity: 0.4;
  cursor: default;
}

.default_img_icon {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-right: 5px;
}

.delete_icon {
  cursor: pointer;
  border: 1px solid #f86c6b;
  color: #f86c6b;
  border-radius: 4px;
}

.add_block {
  border: 1px solid grey;
  border-radius: 4px;
  cursor: pointer;
}

.add_icon {
  width: 3rem !important;
  height: 3rem !important;
}

.flex {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
