.mh-315 {
  min-height: 19.6875rem; // 315px
}

.mh-420 {
  @include media-breakpoint-up(md) {
    min-height: 26.25rem; // 420px
  }
}

.col-checkbox {
  width: 3rem;
}

.w-23 {
  &.card {
    min-width: 18rem;
    width: 90vw;

    @include media-breakpoint-up(sm) {
      min-width: 23rem; // 368px
      width: 23rem;
    }
  }
}

.wh-100 {
  width: 100%;
  height: 100%;
}

.mw-50 {
  max-width: 3.125rem;
}

.mw-172 {
  min-width: 10.75rem;
}

.mw-55 {
  @include media-breakpoint-up(lg) {
    min-width: 55rem;
  }
}

.mw-760 {
  @include media-breakpoint-up(md) {
    min-width: 47.5rem;
  }
}

.fs-12 {
  font-size: 0.75rem;
  line-height: 1.3335;
}

.fs-14 {
  font-size: 0.875rem;
  line-height: 1.35714;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
  line-height: 1.25;
}

.fs-24 {
  font-size: 1.5rem;
  line-height: 1.3334;
}

.mb-med {
  margin-bottom: 1.3125rem;
}

.mb-10 {
  margin-bottom: 0.625rem;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt-6 {
  padding-top: 0.375rem;
}

.pb-8 {
  padding-bottom: 0.5rem !important;
}

.fixed-layout {
  table-layout: fixed;
}

.gap-xs {
  margin-left: -0.1875rem;
  margin-right: -0.1875rem;

  > div {
    padding-left: 0.1875rem;
    padding-right: 0.1875rem;
  }
}

.gap-sm {
  margin-left: -0.375rem;
  margin-right: -0.375rem;

  > div {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
}

.gap-lg {
  @include media-breakpoint-up(md) {
    margin-left: -1.875rem;
  }
}

@include media-breakpoint-down(sm) {
  .gap-xs-sm {
    margin-left: -0.375rem;
    margin-right: -0.375rem;

    > div {
      padding-left: 0.375rem;
      padding-right: 0.375rem;
    }
  }
}

.gap-md {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  > div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.gap-md-d {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  > div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @include media-breakpoint-up(sm) {
    margin-left: -$card-columns-gap / 2;
    margin-right: -$card-columns-gap / 2;

    > div {
      padding-left: $card-columns-gap / 2;
      padding-right: $card-columns-gap / 2;
    }
  }
}

.gap-card {
  margin-left: -0.375rem;
  margin-right: -0.375rem;

  > div {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }

  @include media-breakpoint-up(sm) {
    margin-left: -$card-columns-gap / 2;
    margin-right: -$card-columns-gap / 2;

    > div {
      padding-left: $card-columns-gap / 2;
      padding-right: $card-columns-gap / 2;
    }
  }
}

.bg-transparent {
  background-color: transparent;
}

.fw-md {
  font-weight: 500;
}

.bg-light {
  background: $light;

  .badge-secondary {
    background-color: rgba($white, 0.25);
  }
}

.bg-opt {
  background: $gray-opt;
}

.h-100 {
  height: 100%;
}

.c-green-dark {
  color: $green-dark;
}

.c-gray-opt2 {
  color: $gray-opt2;
}

.c-white {
  color: $white;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-70 {
  margin-top: 4.375rem;
}

.mt-base {
  margin-top: 0.875rem;
}

.mb-base {
  margin-bottom: 0.875rem;
}

.br-xl {
  border-radius: $border-radius-xl;
}
