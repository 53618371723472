.products-table {
  &:last-child {
    margin-bottom: 0;
  }

  th,
  td {
    vertical-align: middle;
  }

  thead th {
    padding-top: 0;
    border-bottom: 0;
  }

  .form-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .link-add-product {
    position: relative;
    overflow: hidden;
  }

  .image-frame {
    background-color: $white;
    border-radius: 0.125rem;
  }

  @include media-breakpoint-down(sm) {
    tbody {
      tr {
        display: flex;
        flex-wrap: wrap;
      }

      td {
        &:not(.variant-ico) {
          width: 25%;
        }
        &.variant-ico + td {
          width: calc(100% - 3.0625rem);
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    tbody {
      td:not(.variant-ico) {
        width: 50%;
      }
    }
  }
}

.variant-row {
  .variant-ico {
    width: 3.0625rem;

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 1.5rem;
      height: 1.0625rem;
      background-image: $arrow-icon;
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: 0.8125rem 1.0625rem;
    }
  }

  &:first-child,
  .variant-btn-row + &,
  &.v-ico {
    .variant-ico::after {
      height: 1.5rem;
      background-color: $white;
      border-radius: $border-radius-sm;
      background-image: $v-icon;
      background-position: 50% 50%;
      background-size: 0.625rem 0.625rem;
    }
  }
}

.col-btns {
  width: 90px;
}

.col-weight {
  width: 124px;
}

.variant-pic {
  width: 2.5rem;
  height: 2.12rem;
  line-height: 1.875rem;
  // background-color: $white;
  // padding: 0.125rem 0.25rem;
  text-align: center;
  // margin-right: 0.25rem;

  .products-table & {
    // background-color: $white;
    margin-right: 0.25rem;
  }

  .image-frame {
    display: block;
    height: 100%;
    overflow: hidden;
  }

  img:not[src^='.svg'],
  img:not[src*='.svg'] {
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    // font-size: .75rem;
    // line-height: 1.25;
    border-radius: $border-radius-sm;
  }

  img[src^='.svg'],
  img[src*='.svg'] {
    overflow: hidden;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .products-table & {
    margin-right: 0;
  }
}

.products-options {
  border-radius: $card-border-radius;
  background-color: $gray-alt;
}

.headline {
  color: $white;
  font-size: 0.875rem;
  line-height: 1.35714;
  font-weight: bold;
  letter-spacing: 0.01875em;
  background-color: $navy-light;
  padding: 0.75rem 1.25rem;

  strong {
    display: inline-block;
    vertical-align: top;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: inherit;
  }

  &:first-child {
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  &::after {
    content: '';
    clear: both;
    display: block;
  }
}

.headline-actions {
  float: right;
  margin-right: -0.25rem;
}

.img-loader-sm,
.img-loader-md {
  position: relative;
  overflow: hidden;
  // .dropzone-input {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   margin: 0;
  //   padding: 0;
  //   width: 100%;
  //   height: 100%;
  //   cursor: pointer;
  // }

  .btn {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -9999px;
    background-color: rgba($navy-dark, 0.85) !important;
    border-radius: $border-radius;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s $transition-function-base;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      margin: -0.5rem 0 0 -0.5rem;
      background-image: $close-icon;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &:hover {
    .btn {
      opacity: 1;
      visibility: visible;
    }
  }
}

.img-loader-sm {
  min-width: 2.5rem;
  width: 2.5rem;
  height: 2.12rem;
}

.img-loader-md {
  min-width: 3.125rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.125rem;
  background-color: $gray-opt;
  border-radius: $border-radius;

  .variant-pic {
    width: 100%;
    height: 100%;
    line-height: 2.812rem;
    display: block;
  }
}
