.form-group {
  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

label {
  color: $white;
  font-weight: 700;

  &.custom-control-label {
    color: inherit;
    font-size: 0.75rem;
    line-height: 1.3335;
    font-weight: inherit;
  }

  .align-items-center & {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-bottom: 0;
  }
}

.custom-control-label {
  &::before,
  &::after {
    top: 0;
  }
}

.form-bar {
  margin-bottom: $form-group-margin-bottom;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-control {
  &,
  &:focus {
    color: $white;
    box-shadow: none !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: yellow !important;
    -webkit-box-shadow: 0 0 0 2.8125rem $input-focus-bg inset !important;
  }

  &.bg-light {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 2.8125rem $light inset !important;
    }
  }

  &.input-sm {
    height: $input-height-sm;
    line-height: $input-line-height-sm;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    &[type='number'] {
      padding-right: 0.1rem;
    }
  }
}

.input-sm.form-control {
  appearance: none;
  &[type='number'] {
    -moz-appearance: 'textfield';
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

textarea.form-control {
  resize: none;
  &,
  &.input-sm {
    height: 10.5rem;
    min-height: 2.1875rem;
  }
}

.actions {
  padding-top: 1.625rem;
  margin-bottom: 1.125rem;

  .card-headline + & {
    padding-top: 1.25rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.link-terms {
  padding: 0;
  display: inline-block;
  vertical-align: top;
  margin-left: 0.4rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  padding-right: 0.75rem !important;
  background-image: none !important;
}

.icon-equally {
  position: relative;
  text-indent: -9999px;
  overflow: hidden;
  width: 1.75rem;
  height: $input-height;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;

  &.icon-sm {
    height: 2.1875rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.5rem;
    height: 0.125rem;
    overflow: hidden;
    margin-left: -0.25rem;
    background-color: $white;
  }

  &::before {
    margin-top: -0.1875rem;
  }

  &::after {
    margin-top: 0.0625rem;
  }
}

input:not(input:-webkit-autofill)::-webkit-credentials-auto-fill-button {
  // background-color: $wite;
}

.form-heading {
  margin-bottom: 40px;

  h2 {
    margin-bottom: 5px;
    font-size: 1.5rem;
    line-height: 1.3333;
  }
}

.form-actions {
  margin: 1.125rem -1rem 0;

  .btn {
    min-width: 7.8125rem;
    margin: 0.5rem 1rem 0;
  }
}
