.non-auth-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
.logo {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
}