.campaign-types {
  margin-top: -1rem;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
}

.campaign-types-item {
  .gap-md & {
    padding-top: 1rem;
  }

  .gap-sm & {
    padding-top: 0.75rem;
  }
}

.campaign-types-link {
  padding: 1.25rem 0.875rem 1.25rem 0.7778rem;
  // margin: 1rem 0.5rem 0;
  background-color: $campaign-types-link-bg;
  border-radius: $border-radius;
  transition: background-color $transition-speed-base $transition-function-base;

  &:hover {
    background-color: $campaign-types-link-bg-hover;
    cursor: pointer;
  }

  @include media-breakpoint-down(xs) {
    padding: 0.5rem;
  }
}

.campaign-types-icon {
  $icon-size: 2.5rem;

  width: $icon-size;
  min-width: $icon-size;
  height: $icon-size;
  border-radius: 50%;
  background-color: $campaign-types-icon-bg-color;
  margin-right: 0.75rem;
  overflow: hidden;

  > span {
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
  }

  @include media-breakpoint-up(sm) {
    $icon-size: 3.125rem;

    width: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    margin-right: 1rem;
  }
}

.icon-appdownloadcampaign {
  background-image: $appdownloadcampaign-icon;
}

.icon-productcampaign {
  background-image: $productcampaign-icon;
}

.icon-videopromo {
  background-image: $videopromo-icon;
}

.icon-bannerad {
  background-image: $bannerad-icon;
}

.icon-categorytakeover {
  background-image: $categorytakeover-icon;
}

.campaign-types-title {
  strong {
    color: $white;
    display: block;
    font-size: 1.125rem;
    line-height: 1.333334;
  }

  em {
    color: $campaign-types-copy-color;
    font-style: inherit;
    font-size: 0.875rem;
    line-height: 1.5;
    display: block;

    @include media-breakpoint-up(sm) {
      font-size: 0.75rem;
    }
  }
}
