.alert {
  color: $navy-dark;
  $icon-size: 22px;
  font-weight: 500;

  &::before {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: $icon-size;
    height: $icon-size;
    overflow: hidden;
    border-radius: 50%;
    background-color: $green-dark;
    margin-right: .5625rem;
    background-image: $custom-checkbox-indicator-icon-checked;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 10px 8px;
  }
}
