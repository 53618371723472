@import '@scss/vendors/_variables';
@import '@scss/_variables';

.page_title {
  max-width: rem(300);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 rem(10);
}

.orders_summary {
  height: rem(607);
}
