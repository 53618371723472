.tabset-area {
  margin-bottom: 1.875rem;

  nav {
    display: block;
    padding-top: 1rem;
  }

  @include media-breakpoint-only(xs) {
    .nav-tabs {
      border: 1px solid $input-border-color;
      border-radius: $border-radius;
      background-color: $dropdown-bg;

      li.active .nav-link,
      .nav-link:hover,
      .nav-link:focus {
        background: $dropdown-link-hover-bg;
      }

      span {
        padding: $input-btn-padding-x $input-btn-padding-y;

        &::after {
          display: none;
        }
      }

      .nav-link {
        border-radius: 0;
      }

      .nav-item {
        width: 100%;
        margin-bottom: 0;

        &:first-child .nav-link {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }

        &:last-child .nav-link {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }

      &.show-tabset {
        .nav-item {
          display: block;
        }
      }
    }
  }

  .btn-show-tabset {
    text-transform: capitalize;
    text-align: left;
  }
}

.nav-tabs {
  margin-bottom: 0;

  li {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.35715;
    font-weight: 400;
  }

  span {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    padding: 0.8125rem 0;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      overflow: hidden;
      background: $blue;
      transition: all $transition-speed-base $transition-function-base;
      transform: translate3D(0, 4px, 0);
      opacity: 0;
    }

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  .nav-link {
    display: block;
    padding: 0;
    border: 0;
    text-transform: capitalize;
  }

  a:hover,
  a:focus,
  li.active .nav-link {
    color: $white;

    span::after {
      opacity: 1;
      transform: translate3D(0, 0, 0);
    }
  }

  @include media-breakpoint-up(xs) {
    display: flex;
    justify-content: space-between;

    li {
      width: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: -2.5rem;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      width: auto;
      margin-left: 2.5rem;
    }
  }
}

.tabset-collapse {
  span {
    display: block;
  }
}
