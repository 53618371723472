@import '@scss/_variables';

.no_data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-weight: bold;

  &.sm {
    font-size: rem(14);
  }

  &.md {
    font-size: rem(16);
  }

  &.lg {
    font-size: rem(24);
  }

  &.xlg {
    font-size: rem(28);
  }
}
