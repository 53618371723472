.img_hovered {
  opacity: 0.3;
}

.img_disabled {
  opacity: 0.4;
  cursor: default;
}

.delete_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 1px solid #f86c6b;
  color: #f86c6b;
  border-radius: 4px;
}
