.game-item {
  position: relative;
  padding-right: 3.75rem;
}

.game-item-link {
  display: flex;
}

.game-item-action {
  position: absolute;
  top: 0.75rem;
  right: 0.875rem;
}

.game-item-pic {
  $pic-size: 3rem;

  min-width: $pic-size;
  width: $pic-size;
  height: $pic-size;
  margin-right: 0.75rem;

  .no-game-img {
    display: block;
    height: 100%;
    background-image: $no-game-icon;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    border-radius: 0.5rem;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    &:not[src^='.svg'],
    &:not[src*='.svg'] {
      border-radius: 0.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    $pic-size: 3.5rem;

    min-width: $pic-size;
    width: $pic-size;
    height: $pic-size;
  }

  @include media-breakpoint-up(md) {
    $pic-size: 5rem;

    min-width: $pic-size;
    width: $pic-size;
    height: $pic-size;
  }
}

.game-item-title {
  display: block;
  @extend .fs-18;
  margin-bottom: 0.25rem;
}

.game-item-category {
  @extend .fs-12;
  display: block;
  color: $body-color;
  font-weight: inherit;
  font-style: inherit;
  font-size: 0.75rem;
  line-height: 1.5;
}
