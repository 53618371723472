.info-bar {
  margin-top: 2.1875rem;

  h3 {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
  }

  &:first-child {
    margin-top: 0;
  }

  .info-img {
    width: 3.75rem;
    height: 3.75rem;
    display: inline-block;
    vertical-align: middle;
    padding: 0.3125rem;
    background-color: $secondary;
    border-radius: $border-radius-md;
    margin-right: 1.25rem;

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}

.info-product {
  .info-img {
    background-color: $white;
  }

  .info-title {
    color: $white;
    font-size: 1.125rem;
    line-height: 1.3889;
    font-weight: $font-weight-bold;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 5rem);
  }
}

.info-advertised {
  .info-img {
    width: auto;
    height: auto;
    padding: 0.1204rem 1.4375rem;

    img {
      max-height: none;
    }
  }
}

.info-demographic {
  color: $white;

  .info-img {
    line-height: 3.75rem;
    padding: 0;
    text-align: center;
  }

  .info-value {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.125rem;
  }
}
