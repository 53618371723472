.file-list {
  height: 100%;

  img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3D(-50%, -50%, 0);
    max-width: 98%;
    max-height: 98%;
    width: auto;
    height: auto;
  }

  .image-frame {
    min-width: 100%;
    position: relative;
    display: block;
  }
}

.file-list .btn,
.btn.btn-trashcan {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $border-radius-sm;
  text-indent: -9999px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  &,
  &:not(.disabled):hover,
  &:not(.disabled):focus {
    background-color: rgba($navy-dark, 0.85);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 17px;
    margin: -8px 0 0 -7px;
    background-image: $trashcan-icon;
    background-repeat: no-repeat;
  }
}

.file-list-row {
  @include make-row();
}

.file-list-item {
  margin-bottom: 1rem;

  @include make-col-ready();
  @include make-col(6);

  @media screen and (min-width: 480px) {
    @include make-col(4);
  }

  @include media-breakpoint-up(sm) {
    @include make-col(3);
  }

  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  @media screen and (min-width: 1400px) {
    @include make-col(4);
  }

  &.item-link:first-child {
    max-width: 100% !important;
    flex: 0 0 100%;
  }

  > div:not(.link-add-product) {
    display: flex;
    height: 100%;
    position: relative;
    padding: 0.4375rem 0.5rem;
    background-color: $white;
    border-radius: $border-radius-sm;

    &:hover {
      .btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
