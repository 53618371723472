.game-infobar {
  &,
  .card {
    margin-bottom: 0.75rem;
  }

  @include media-breakpoint-up(sm) {
    margin-bottom: 0;

    .card {
      margin-bottom: $card-columns-gap;
    }
  }
}

.info-box {
  color: $white;
  text-align: center;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}

.info-title {
  display: block;
  font-style: normal;

  @include media-breakpoint-up(xl) {
    padding-top: 0.25rem;
  }
}

.info-value {
  display: block;
  font-size: 1.5rem;
  line-height: 1.3335;
}

.info-icon {
  $icon-size: 2.75rem;

  display: block;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  background-color: rgba($white, 0.2);
  border-radius: 50%;
  margin: 0 auto 0.75rem;

  i {
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50% 50%;

    &.icon_sale {
      background-position: 52% 52%;
    }
  }

  @include media-breakpoint-up(xl) {
    $icon-size: 4.375rem;

    float: right;
    width: $icon-size;
    height: $icon-size;
    line-height: $icon-size;
    margin: 0 0 0 0.5rem;
  }
}

.icon_user {
  background-image: $user-icon;
}

.icon_impression {
  background-image: $impression-icon;
}

.icon_sale {
  background-image: $sale-icon;
}

.icon_revenue {
  background-image: $revenue-icon;
}
