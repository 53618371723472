.graph-data {
  margin-top: 2.1875rem;
}

.graph-info {
  display: block;
  width: 0.625rem;
  border-radius: 6.25rem;
  background-color: $white;
  margin: 0 auto 0.5rem;
  opacity: 0.25;

  .graph-max & {
    opacity: 1;
  }
}
