a {
  color: $white;
  font-weight: bolder;

  &.go-back {
    color: $blue;
  }

  p &:hover {
    opacity: 0.9;
  }
}

.btn {
  &.disabled,
  &:disabled {
    opacity: 0.25;
  }

  &:not(.disabled).btn-secondary:hover,
  &:not(.disabled).btn-secondary:focus,
  &:not(.disabled).btn-secondary:active,
  .show &.btn-secondary {
    background-color: $dropdown-bg;
    border-color: $dropdown-bg;
    box-shadow: none;
  }

  &.btn-light:not(.btn-secondary) {
    color: $navy-dark;
    background-color: $white;
    transition: opacity $transition-speed-base $transition-function-base;

    &:not(.disabled):hover,
    &:not(.disabled):focus,
    &:not(.disabled):active {
      opacity: 0.9;
    }

    &.disabled,
    &:disabled {
      opacity: 0.7 !important;
    }
  }
}

.btn-block + .btn-block {
  margin-top: 0.875rem;
}

.btn-outline-secondary {
  color: $gray-light;
  background-color: transparent;
  border: 1px solid $gray-light;

  &:not(.disabled):focus,
  &:not(.disabled):hover {
    color: $white;
    background-color: $gray-light;
    border-color: $gray-light;
  }

  &.disabled,
  &:disabled {
    color: $gray-light;
    background-color: transparent;
    opacity: 0.5;
    border-color: $gray-light;
  }
}

.btn-back {
  $size: 2.25rem;

  width: $size;
  height: $size;
  line-height: $size;
  overflow: hidden;
  border-radius: 50%;
  background-color: $navy-light;
  padding: 0;
  border: 0;
  text-align: center;

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 8px;
    background-image: $back-icon;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &:focus,
  &:hover {
    background-color: $gray-light;
  }

  .topbar & {
    // margin: -0.125rem 1.375rem 0 0;
    position: absolute;
    top: -0.125rem;
    left: 0;
  }
}

.btn-close,
.btn-add {
  $btn-size: 34px;
  $icon-size: 14px;

  position: relative;
  width: $btn-size;
  height: $btn-size;
  border: 0;
  padding: 0;

  &.disabled,
  &:disabled {
    opacity: 0.8;
  }

  &,
  &.disabled,
  &:disabled {
    background-color: $navy-light;
  }

  &.disabled,
  &:disabled {
    background-color: $navy-light !important;

    &::before,
    &::after {
      background-color: $gray-light !important;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: $icon-size;
    height: 1px;
    overflow: hidden;
    background-color: $gray-light;
    margin-left: -$icon-size / 2;
    transition: background-color $transition-speed-base $transition-function-base;
  }

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    background-color: $navy-dark !important;

    &::before,
    &::after {
      background-color: $white;
    }
  }
}

.btn-close {
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.btn-add {
  &::after {
    transform: rotate(-90deg);
  }
}

.view-all {
  color: $gray-light;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 0.0153em;
  font-weight: 600;
  padding: 0;
}

.link-terms {
  font-weight: inherit;
}

.btn-remove {
  $btn-size: 14px;
  $icon-size: 10px;

  position: relative;
  width: $btn-size;
  height: $btn-size;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  text-indent: -9999px;
  margin-left: 0.25em;
  padding: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: $icon-size;
    height: 1px;
    overflow: hidden;
    background-color: $white;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.link-add-option {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  background: transparent !important;
  padding: 0;
  border: 0;

  &:not(.disabled) {
    cursor: pointer;
  }

  .icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $navy-light;
    border: 1px solid $navy-dark;
    border-radius: $border-radius-sm;
    margin-right: 0.875rem;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.625rem;
      height: 1px;
      background-color: $gray-light;
      margin-left: -0.3125rem;
      transition: background-color $transition-speed-base $transition-function-base;
    }

    &::after {
      transform: rotate(-90deg);
    }
  }

  &:not(.disabled):hover,
  &:not(.disabled):focus {
    background: transparent;

    .icon::before,
    .icon::after {
      background-color: $white;
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-delete {
  letter-spacing: 0.0153em;
  padding-right: 1.25rem;
  white-space: nowrap;

  &::before {
    content: '';
    width: 1rem;
    height: 1.25rem;
    display: inline-block;
    vertical-align: top;
    margin-top: -2px;
    background-image: $delete-icon-alt;
    background-repeat: no-repeat;
    font-weight: normal;
    margin-right: 1rem;
    opacity: 0.2;
    transition: opacity $transition-speed-base $transition-function-base;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  .topbar & {
    margin-bottom: 0.5rem;
    vertical-align: top;
  }
}
