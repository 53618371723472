.customer-info {
  color: $white;

  a {
    font-weight: inherit;
  }

  dl {
    margin: 0;
  }

  dt {
    display: block;
    margin-top: 1.875rem;
    font-weight: $font-weight-bold;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    font-size: 1.125rem;
    line-height: 1.3889;
  }
}
