.form-check.radio {
  *,
  *::before,
  *::after {
    cursor: default;
  }

  padding-left: 0;

  $icon-size: 1rem;

  .form-check-label {
    z-index: 10;
    color: $body-color;
    position: relative;
    padding-left: 1.5rem;
    display: block;
    min-height: $icon-size;
  }

  .radio-icon {
    width: $icon-size;
    height: $icon-size;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border: 1px solid rgba($white, 0.5);
    transition: background-color $transition-speed-base $transition-function-base,
      border-color $transition-speed-base $transition-function-base;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.75rem;
      height: 0.6rem;
      margin: -0.282rem 0 0 -0.375rem;
      overflow: hidden;
      background-image: $custom-checkbox-indicator-icon-checked;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 75% 75%;
      transition: opacity $transition-speed-base $transition-function-base;
      opacity: 0;
    }
  }

  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 2rem;
    height: 2px;
    margin: 0;

    &:checked ~ .radio-icon {
      border-color: $blue;
      background-color: $blue;

      &::after {
        opacity: 1;
      }
    }

    &:checked ~ span {
      color: $white;
    }

    &:checked + label + .custom-button-details {
      &::before {
        background-color: $white;
      }

      .custom-button-price {
        color: $navy-dark;
      }

      .custom-button-icon {
        background-color: $blue;
        border-color: $blue;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  input:disabled ~ .form-check-label::before {
    background-color: inherit;
    opacity: 0.5;
  }
}
