.form-group-addon {
  position: relative;

  .form-control {
    padding-left: 2.3125rem;
    border-radius: $border-radius !important;
  }
}

.ico-label {
  z-index: 10;
  position: absolute;
  top: 0;
  left: .75rem;
  height: $input-height;
  line-height: calc(#{$input-height} - 2px);
  transition: opacity $transition-speed-base ease-in-out, background-color $transition-speed-base ease-in-out;
  margin: 0;
  opacity: .5;

  .input-touched &,
  .input-active & {
    opacity: 1;
  }

  path {
    fill: $white;

    .input-invalid & {
      fill: $red;
    }

    .input-valid & {
      fill: $green;
    }
  }
}
