.tags-control {
  margin-bottom: $form-group-margin-bottom;

  &:last-child {
    margin-bottom: 0;
  }

  .react-tagsinput {
    height: auto;
    min-height: 10.5rem;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 0.4375rem 0.6875rem 0.0625rem;
    // font-size: $font-size-base;
    // line-height: $line-height-base;
    // color: $input-color;
    // background-color: $input-bg;
    background: $light;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
  }

  .react-tagsinput-tag {
    background-color: $blue;
    border-radius: $border-radius;
    border: 0;
    color: $white;
    font-family: inherit;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: 0.4px;
    margin-bottom: 0.375rem;
    margin-right: 0.375rem;
    padding: 0.125rem 0.3125rem 0.125rem 0.5rem;
    cursor: default;

    a {
      &,
      &::before {
        font-size: 1rem;
        line-height: 1rem;
        vertical-align: top;
      }
    }
  }

  .react-tagsinput-input {
    color: $white;
    height: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    vertical-align: top;
    margin-top: 0;

    &::placeholder {
      color: $input-placeholder-color;
    }
  }
}
